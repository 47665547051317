<template>
  <PaymentIndex
    :qso="qso"
    :pageTitle="
      $t('components.fleetDetailsManagement.diveIntoTab.payments.headline')
    "
    :endpoint="endpoint"
    :filterItems="filterOptions"
  />
</template>

<script>
import PaymentIndex from '@/composites/transaction/payment/index/Index.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'ViewFleetPayments',

  components: {
    PaymentIndex,
  },
  data() {
    return {
      qso: { append: '', prepend: '' },
      endpoint: null,
      filterOptions: [
        {
          key: 'payment_type',
          type: 'checkbox',
          input: [
            { text: 'Topup', value: 'Topup' },
            { text: 'Agent Topup', value: 'Agent Topup' },
            { text: 'Initial Balance', value: 'Initial Balance' },
            {
              text: 'Invitee Invitation Amount',
              value: 'Invitee Invitation Amount',
            },
            {
              text: 'Inviter Invitation Amount',
              value: 'Inviter Invitation Amount',
            },
            { text: 'Rider Balance Adjust', value: 'Rider Balance Adjust' },
          ],

          title: 'Payment Type',
        },
        {
          key: 'refund_status',
          type: 'checkbox',
          input: [
            { text: 'Refunded', value: 'refunded' },
            { text: 'Not Refunded', value: 'not-refunded' },
          ],

          title: 'Refund Status',
        },
      ],
    }
  },
  created() {
    this.generateEndPoint()
  },

  methods: {
    generateEndPoint() {
      this.endpoint =
        useEndpoints.invoice.indexOfPayment() +
        `fleet=${this.$route.params.id}&`
    },
  },
}
</script>
